import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import ProjectImg1 from "../../assets/img/projects/projectimage1.jpeg";
import ProjectImg2 from "../../assets/img/projects/projectimage3.jpeg";
import ProjectImg3 from "../../assets/img/projects/projectimage2.jpeg";

import AddImage2 from "../../assets/img/projects/projectimage5.jpeg";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Impressive Projects</h1>
            <p className="font15">
              EDA CLOVERLAND takes pride in delivering exceptional engineering solutions for various industries. Our projects

              showcase our commitment to innovation, safety, and sustainability, providing cutting-edge solutions that

              exceed expectations.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Nuri Smart Meter"
                text="EDA CLOVERLAND provided innovative engineering solutions for the Nuri Smart Meter project, ensuring accurate and efficient energy monitoring."
                action={() => alert("Clicked")}
              />

            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="VVS Extension of LV"
                text="EDA CLOVERLAND provided comprehensive engineering services for the VVS extension of LV project, ensuring efficient and reliable systems."
                action={() => alert("Clicked")}
              />

            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="High-Tension and Transformer Installation"
                text="EDA CLOVERLAND successfully executed the high-tension and transformer installation project, demonstrating expertise in power infrastructure."
                action={() => alert("Clicked")}
              />


            </div>

            <div className="row flexCenter">
              <div style={{ margin: "50px 0", width: "200px" }}>
                {/* <FullButton title="Load More" action={() => alert("clicked")} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" style={{ height: "607px", width: "426px" }} />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about EDA CLOVERLAND</h4>
              <h2 className="font35 extraBold">A Study of Creativity</h2>
              <p className="font16">
                We deliver exceptional engineering
                solutions that meet the
                highest standards of quality, safety, and sustainability,
                while building long-term relationships with our clients
                and contributing to the betterment of society.
              </p>

            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
        <TestimonialBox
            text="EDA CLOVERLAND has been an outstanding partner in our engineering projects. Their dedication to innovation, safety, and sustainability has consistently exceeded our expectations. The expertise and professionalism of their team make them a top choice for any engineering needs."
            author="CEO "
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
            <TestimonialBox
            text="EDA CLOVERLAND has consistently delivered exceptional engineering solutions that have significantly impacted our projects. Their expertise in mechanical, electrical, civil, and software engineering, combined with their commitment to safety and sustainability, makes them a trusted partner. We highly recommend their services for projects of all sizes."
            author="Satisfied Client"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Working with EDA CLOVERLAND has been an absolute pleasure. Their team's expertise in engineering and dedication to delivering high-quality solutions is truly commendable. I highly recommend EDA CLOVERLAND for any engineering project."
            author="Satisfied Client"
          />

        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="EDA CLOVERLAND's commitment to excellence and innovation sets them apart in the engineering industry. Their team's expertise in mechanical, electrical, civil, and software engineering is truly impressive. I highly recommend EDA CLOVERLAND for any engineering project."
            author="Satisfied Client"
          />

        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Working with EDA CLOVERLAND has been a transformative experience for our projects. Their expertise in mechanical, electrical, civil, and software engineering is unmatched. EDA CLOVERLAND's commitment to innovation, safety, and sustainability makes them a valuable partner for any project."
            author="Satisfied Client"
          />

        </LogoWrapper>
        {/* <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it."
            author="Ralph Waldo Emerson"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it."
            author="Ralph Waldo Emerson"
          />
        </LogoWrapper> */}
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;

import React from "react";
import styled from "styled-components";
// Assets
import { SiAntdesign } from "react-icons/si";
import { MdOutlineManageAccounts } from "react-icons/md";
import { FaTripadvisor } from "react-icons/fa";
import { SiPytest } from "react-icons/si";
import RollerIcon from "../../assets/svg/Services/RollerIcon";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <StyledIcon as={SiAntdesign} />;
      break;
    case "monitor":
      getIcon = <StyledIcon as={MdOutlineManageAccounts} />;
      break;
    case "browser":
      getIcon = <StyledIcon as={FaTripadvisor} />;
      break;
    case "printer":
      getIcon = <StyledIcon as={SiPytest} />;
      break;
    default:
      getIcon = <StyledRollerIcon />;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font16">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;

const StyledIcon = styled.div`
  width: 50px;
  height: 50px;
`;

const StyledRollerIcon = styled(RollerIcon)`
  width: 50px;
  height: 50px;
`;

const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;

const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gallery from "../Sections/Gallery";
import Landing from "../../screens/Landing";

export default function Router() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="gallery" element={<Gallery />} />
      {/* <Route path="gallery" element={<Gallery />} /> */}
      {/* Add other routes here */}
      {/* <Route path="contact" element={<Contact />} /> */}
      {/* <Route path="*" element={<NoPage />} /> */}
    </Routes>
  </BrowserRouter>
  );
}

import React, { useState } from 'react';
import styled from "styled-components";
import TopNavbar from "../Nav/TopNavbar";
import "../gallery.css"

import projectimage1 from '../../assets/gallery/projectimage1.jpeg';
import projectimage2 from '../../assets/gallery/projectimage2.jpeg';
import projectimage3 from '../../assets/gallery/projectimage3.jpeg';
import galleryImage1 from '../../assets/gallery/galleryImage1.jpeg';
import galleryImage2 from '../../assets/gallery/galleryImage2.jpeg';
import galleryImage3 from '../../assets/gallery/galleryImage3.jpeg';
import galleryImage4 from '../../assets/gallery/galleryImage4.jpeg';
import galleryImage5 from '../../assets/gallery/galleryImage5.jpeg';
import galleryImage6 from '../../assets/gallery/galleryImage6.jpeg';
import galleryImage7 from '../../assets/gallery/galleryImage7.jpeg';
import galleryImage8 from '../../assets/gallery/galleryImage8.jpeg';
import galleryImage9 from '../../assets/gallery/galleryImage9.jpeg';
import galleryImage10 from '../../assets/gallery/galleryImage10.jpeg';
import galleryImage11 from '../../assets/gallery/galleryImage11.jpeg';
import galleryImage12 from '../../assets/gallery/galleryImage12.jpeg';
import galleryImage13 from '../../assets/gallery/galleryImage13.jpeg';
import galleryImage14 from '../../assets/gallery/galleryImage14.jpeg';
import galleryImage15 from '../../assets/gallery/galleryImage15.jpeg';
import galleryImage16 from '../../assets/gallery/galleryImage16.jpeg';
import galleryImage17 from '../../assets/gallery/galleryImage17.jpeg';
import galleryImage18 from '../../assets/gallery/galleryImage18.jpeg';
import galleryImage19 from '../../assets/gallery/galleryImage19.jpeg';
import galleryImage20 from '../../assets/gallery/galleryImage20.jpeg';
import galleryImage21 from '../../assets/gallery/galleryImage21.jpeg';
import galleryImage22 from '../../assets/gallery/galleryImage22.jpeg';
import galleryImage23 from '../../assets/gallery/galleryImage23.jpeg';
import galleryImage24 from '../../assets/gallery/galleryImage24.jpeg';
import galleryImage25 from '../../assets/gallery/galleryImage25.jpeg';
import galleryImage26 from '../../assets/gallery/galleryImage26.jpeg';
import galleryImage27 from '../../assets/gallery/galleryImage27.jpeg';
import galleryImage28 from '../../assets/gallery/galleryImage28.jpeg';
import galleryImage29 from '../../assets/gallery/galleryImage29.jpeg';
import galleryImage30 from '../../assets/gallery/galleryImage30.jpeg';
import galleryImage31 from '../../assets/gallery/galleryImage31.jpeg';
import galleryImage32 from '../../assets/gallery/galleryImage32.jpeg';
import galleryImage33 from '../../assets/gallery/galleryImage33.jpeg';
import galleryImage34 from '../../assets/gallery/galleryImage34.jpeg';

const images = [
    projectimage1, projectimage2, projectimage3, galleryImage1,
    galleryImage2, galleryImage3, galleryImage4, galleryImage5, galleryImage6,
    galleryImage7, galleryImage8, galleryImage9, galleryImage10, galleryImage11,
    galleryImage12, galleryImage13, galleryImage14, galleryImage15, galleryImage14,
    galleryImage15, galleryImage16, galleryImage17, galleryImage18, galleryImage19,
    galleryImage20, galleryImage21, galleryImage22, galleryImage23, galleryImage24,
    galleryImage25, galleryImage26, galleryImage27, galleryImage28, galleryImage29,
    galleryImage30, galleryImage31, galleryImage32, galleryImage33, galleryImage34
];


export default function Gallery() {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Wrapper id="Gallery">
            <TopNavbar />3
            <Content>
                <div>
                    <div className="gallery">
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Gallery ${index}`}
                                className="gallery-image"
                                onClick={() => {
                                    setPhotoIndex(index);
                                    setIsOpen(true);
                                }}
                            />
                        ))}
                    </div>

                    {isOpen && (
                        <div className="lightbox-overlay" onClick={() => setIsOpen(false)}>
                            <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
                                <button className="close-button" onClick={() => setIsOpen(false)}>
                                    &times;
                                </button>
                                <img src={images[photoIndex]} alt={`Gallery ${photoIndex}`} className="lightbox-image" />
                            </div>
                        </div>
                    )}
                </div>
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled.section`
  width: 100%;
  margin: 0px;
`;

const Content = styled.div`
  padding: 20px;
  margin-top: 80px; /* Adjust based on your navbar height */
`;
